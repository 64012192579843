import React from 'react';

import { Typography, Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { BodyFeature } from '../Industry/Features/BodyFeature';
import { WaveDownSVG } from '../WaveSVGs/WaveDownSVG';
import { WaveUpSVG } from '../WaveSVGs/WaveUpSVG';

const useStyles = makeStyles((theme) => ({
	heading: {
		// padding: ' 0 0 2rem 0',
		textAlign: 'center',
	},
	subheader: {
		lineHeight: '32px',
	},
}));

export const ApiBody = ({ introHeader, introBody, featureSection }) => {
	const classes = useStyles();

	return (
		<div style={{ background: '#FFF', padding: '4rem 0' }}>
			<Container>
				<Grid
					container
					direction='column'
					justifyContent='center'
					alignContent='center'
					spacing={2}
					className={classes.heading}>
					<Grid item>
						<Typography variant='h2' color='primary'>
							{introHeader}
						</Typography>
					</Grid>

					<Grid item xs={12} md={10}>
						<Typography body='body1' className={classes.subheader}>
							{introBody}
						</Typography>
					</Grid>
				</Grid>
			</Container>
			{featureSection.map((feature, index) =>
				index === 0 ? (
					<>
						<WaveDownSVG fill='#FFF' shadow />
						<div style={{ background: '#F5F9FF', padding: '14rem 0 10rem 0' }}>
							<Container>
								<BodyFeature feature={feature} index={index} key={index} />
							</Container>
						</div>
						<WaveUpSVG fill='#FFF' shadow />
					</>
				) : (
					<Container>
						<BodyFeature feature={feature} index={index} key={index} />
					</Container>
				)
			)}
		</div>
	);
};
